import { Autocomplete, Grid, TextField } from '@emburse/embark';
import { ICompanyRequestSignUpInfo } from './CompanyRequestSignUpInfo';

interface IOption {
  name: string;
  value: string;
}

const BILLING_PLANS: IOption[] = [
  { value: 'spend_basic_annual_contract', name: 'Basic' },
  { value: 'spend_plus_annual_contract', name: 'Plus' }
];
const EMBURSE_CARDS_OPTIONS: IOption[] = [
  { name: 'Propose', value: 'offer' },
  { name: 'No', value: 'hide' }
];
const IMPLEMENTATION_OPTIONS: IOption[] = [
  { name: 'Guided Implementation', value: 'guided' },
  { name: 'Self-guided (free)', value: 'self_guided' }
];

export function CompanyRequestEmburseSignUpInfo({ companyRequest, onChange }: ICompanyRequestSignUpInfo): JSX.Element {
  return (
    <>
      <Grid container direction="row" spacing={5} wrap="wrap">
        <Grid item xs={6} md={3}>
          <TextField
            id="company_name"
            type="text"
            value={companyRequest.company_name}
            label="Company Name"
            onChange={(event: any) => onChange(event.target.value, 'company_name')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            required
            id="salesforce_id"
            type="text"
            value={companyRequest.salesforce_id}
            label="Salesforce ID"
            onChange={(event: any) => onChange(event.target.value, 'salesforce_id')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Autocomplete
            id="billing_plan"
            options={BILLING_PLANS}
            defaultValue={BILLING_PLANS.find((option) => option.value === companyRequest?.request_options?.billing_plan)}
            label="Billing Plan"
            onChange={(event: any, option: any) => onChange(option?.value, 'request_options', 'billing_plan')}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params: any) => <TextField {...params} label="Billing Plan" required />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Autocomplete
            id="emburse_cards"
            options={EMBURSE_CARDS_OPTIONS}
            defaultValue={EMBURSE_CARDS_OPTIONS.find((option) => option.value === companyRequest?.request_options?.emburse_cards)}
            label="Emburse Cards Option"
            onChange={(event: any, option: any) => onChange(option?.value, 'request_options', 'emburse_cards')}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params: any) => <TextField {...params} label="Emburse Cards Option" required />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Autocomplete
            id="implementation"
            options={IMPLEMENTATION_OPTIONS}
            defaultValue={IMPLEMENTATION_OPTIONS.find((option) => option.value === companyRequest?.request_options?.implementation)}
            onChange={(event: any, option: any) => onChange(option?.value, 'request_options', 'implementation')}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params: any) => <TextField {...params} label="Implementation" required />}
          />
        </Grid>
      </Grid>
    </>
  );
}
