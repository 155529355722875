import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../../../types/api/serialized_company_request';
import { useAuth0 } from '@auth0/auth0-react';
import { CompanyRequestSVBSignUpInfo } from './CompanyRequestSVBSignUpInfo';
import { CompanyRequestEmburseSignUpInfo } from './CompanyRequestEmburseSignUpInfo';

export interface ICompanyRequestSignUpInfo {
  companyRequest: ISerializedCompanyRequest;
  onChange: (value: any, field: ISerializedCompanyRequestKeys, nestedKey?: string) => void;
}

export function CompanyRequestSignUpInfo({ companyRequest, onChange }: ICompanyRequestSignUpInfo): JSX.Element {
  const { user } = useAuth0();
  const isSVB = !user.organization_name || user.organization_name.toLowerCase() === 'svb';

  return (
    <>
      {isSVB ? (
        <CompanyRequestSVBSignUpInfo companyRequest={companyRequest} onChange={onChange} />
      ) : (
        <CompanyRequestEmburseSignUpInfo companyRequest={companyRequest} onChange={onChange} />
      )}
    </>
  );
}
