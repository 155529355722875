import { Grid, TextField } from '@emburse/embark';
import { ICompanyRequestSignUpInfo } from './CompanyRequestSignUpInfo';


export function CompanyRequestSVBSignUpInfo({ companyRequest, onChange }: ICompanyRequestSignUpInfo): JSX.Element {
  return (
    <>
      <Grid container direction="row" spacing={5} wrap="wrap">
        <Grid item xs={6} md={3}>
          <TextField
            id="company_name"
            type="text"
            value={companyRequest.company_name}
            label="Company Name"
            onChange={(event: any) => onChange(event.target.value, 'company_name')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            id="partner_unique_id"
            type="text"
            value={companyRequest.partner_unique_id}
            label="Partner Id/CIF"
            onChange={(event: any) => onChange(event.target.value, 'partner_unique_id')}
          />
        </Grid>
      </Grid>
    </>
  );
}
